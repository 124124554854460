<div
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
      >
        <div class="grid gap-y-2 grid-cols-1" [formGroup]="form">
          <div class="text-start">
            <h3
              class="text-base font-medium leading-6 text-gray-900"
              id="modal-title"
            >
              {{ "createTicket" | transloco }}
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                {{ "createTicketDesc" | transloco }}
              </p>
            </div>
          </div>
          <div>
            <label
              for="setor"
              class="block text-sm font-medium leading-6 text-gray-900"
              >{{ "departament" | transloco }}*</label
            >
            <select
              id="setor"
              name="setor"
              formControlName="departmentId"
              class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <ng-container>
                <option *ngFor="let group of departament" [value]="group.id">
                  {{ group.name }}
                </option>
              </ng-container>
            </select>
          </div>
          <div>
            <label
              for="urgency"
              class="block text-sm font-medium leading-6 text-gray-900"
              >{{ "urgency" | transloco }}*</label
            >
            <select
              id="urgency"
              name="urgency"
              formControlName="urgency"
              class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-light-blue sm:text-sm sm:leading-6"
            >
              <option [value]="null" selected disabled>
                {{ "SelectUrgency" | transloco }}
              </option>
              <option [value]="urgency.Lowest">
                {{ "lowest" | transloco }}
              </option>
              <option [value]="urgency.Low">{{ "low" | transloco }}</option>
              <option [value]="urgency.Medium">
                {{ "medium" | transloco }}
              </option>
              <option [value]="urgency.High">{{ "high" | transloco }}</option>
              <option [value]="urgency.Critical">
                {{ "critical" | transloco }}
              </option>
            </select>
          </div>
          <div>
            <label
              for="assunto"
              class="block text-sm font-medium leading-6 text-gray-900"
              >{{ "subject" | transloco }}*</label
            >
            <div class="mt-2 relative">
              <input
                type="text"
                name="assunto"
                id="assunto"
                formControlName="subject"
                maxlength="20"
                (input)="updateCharacterCount()"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-light-blue sm:text-sm sm:leading-6"
                placeholder="Ex: Alterar dados cadastrais"
              />
              <span
                class="counter inline-block text-xs text-gray-400 absolute right-0"
                >{{ form.get("subject").value?.length || 0 }}/20</span
              >
            </div>
          </div>
          <div>
            <label
              for="message"
              class="block text-sm font-medium leading-6 text-gray-900"
              >{{ "message" | transloco }}*</label
            >
            <div class="mt-2">
              <textarea
                rows="4"
                name="message"
                id="message"
                formControlName="description"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-light-blue sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="flex justify-end grid-cols-2 gap-x-2 mt-5 sm:mt-6">
          <button
            type="button"
            (click)="cancel()"
            class="rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {{ "cancel" | transloco }}
          </button>
          <button
            type="button"
            (click)="createTicket()"
            class="rounded-md bg-pink-500 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-300 transition delay-150ms focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {{ "newTicket" | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
