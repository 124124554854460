import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { CurrentStudentClient, CurrentUserClient } from "api-client";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
    providedIn: 'root'
})
export class TermsResolver implements Resolve<any> {

    constructor(
        private usersClient: CurrentStudentClient,
        private route: Router,
        private loaderService: NgxUiLoaderService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loaderService.start();

        this.usersClient.getCurrentUser().subscribe(user => {
            if (user.termUsers?.length > 0) {
                this.route.navigate(['/terms-redirect']).then(() => { });
            }
            
            this.loaderService.stop()
        });
    }
}