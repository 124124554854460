import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { LOCALE_ID } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { environment } from 'src/environments/environment';
import { TranslocoRootModule } from './transloco-root.module';
import { MatIconModule } from '@angular/material/icon';
import { HomeComponent } from './modules/user/home/home.component';
import { NavbarComponent } from './modules/utils/navbar/navbar.component';
import { LanguageSelectorComponent } from './modules/utils/language-selector/language-selector.component';
import { IonicModule } from '@ionic/angular';
import { BottomNavComponent } from './modules/utils/bottom-nav/bottom-nav.component';
import { SharedModule } from './modules/shared/shared.module';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';

import { INSTRUCTOR_WEB_BASE_URL } from './services/config/config.constants';
import { API_BASE_URL } from 'api-client';
import { CLASSROOM_API_BASE_URL } from 'classroom-api-client';
import { IconsModule } from './modules/shared/icons.module';
import { MatInputModule } from '@angular/material/input';
import { InvoicesComponent } from './modules/user/profile/invoices/invoices.component';
import { InvoiceViewComponent } from './modules/user/profile/invoices/invoice-view/invoice-view.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: 'three-bounce', // Tipo de spinner (veja opções abaixo)
  fgsSize: 60, // Tamanho do spinner
  fgsColor: '#007bff', // Cor do spinner
  pbDirection: 'ltr', // Direção da progress bar
  pbThickness: 5, // Espessura da progress bar
  logoUrl: "assets/images/logo/logo.svg",
  logoSize: 200
};

registerLocaleData(localeBr, 'pt')

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    BottomNavComponent,
    LanguageSelectorComponent,
    InvoiceViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxMaskDirective, 
    NgxMaskPipe,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    MatMenuModule,
    MatButtonModule,
    InfiniteScrollModule,
    SharedModule,
    MatTableModule,
    MatDialogModule,
    TranslocoRootModule,
    MatIconModule,
    NoopAnimationsModule,
    IonicModule.forRoot(),
    IconsModule,
    MatInputModule,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl
    },
    {
      provide: CLASSROOM_API_BASE_URL,
      useValue: environment.classRommApiBaseUrl
    },
    {
      provide: INSTRUCTOR_WEB_BASE_URL,
      useValue: environment.instructorBaseUrl
    },
    provideEnvironmentNgxMask(),
    provideNgxMask(),
    DatePipe, {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
