import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { CreatePaymentCommand, InvoiceClient, InvoiceStatus, InvoiceType, InvoiceViewQueryResponse, PaymentClient, PaymentType } from 'api-client';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent {

  InvoiceStatus = InvoiceStatus;
  paymentType = PaymentType;
  invoiceType = InvoiceType;


  invoiceId: string;
  invoice: InvoiceViewQueryResponse;
  selectedPaymentType: PaymentType;

  loading: boolean = false;

  pix?: null |  {
    base64: string;
    copyAndPaste: string;
  }

  boleto?: | {
    url: string;
  }

  public buttonDisabled: boolean = false;

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private invoiceClient: InvoiceClient,
    private readonly paymentClient: PaymentClient, 
    private clipboard: Clipboard, 
    private notificationService: NotificationService) {
    
  }

  ngOnInit(): void {
    this.invoiceId = this.route.snapshot.params['id'];
    this.getInvoice();
  }

  getInvoice() {
    this.loading = true;
    this.invoiceClient.view(this.invoiceId).subscribe(data => {
      this.invoice = data;
      this.loading = false;
    });
  }

  selectPaymentMethod(paymentType: PaymentType) {
    this.selectedPaymentType = paymentType;
  }

   generatePayment(){
      this.buttonDisabled = true;
      this.paymentClient.createPayment(<CreatePaymentCommand>{
        invoiceGuide: this.invoiceId,
        paymentType: this.selectedPaymentType
      }).subscribe({
        next: (response) => {

          if (response.type == PaymentType.PIX) {
            this.pix = {
              base64: `data:image/png;base64,${ response.url }`  ,
              copyAndPaste: response.pixCopyAndPaste
            }
          }

          if (response.type == PaymentType.Boleto) {
            this.boleto = {
              url: response.url
            }
          }
       
        },
        error: (err) => {
          console.error(err)
        },
        complete: () => {
          this.buttonDisabled = false;
        }
      });
    }
  

    public copyLink(value: string){
      this.clipboard.copy(value);
      this.notificationService.info('Copiado para a área de transferência');
    }

}
