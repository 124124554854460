import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth-guard.service';
import { NoAuthGuard } from './services/auth/noAuth.guard';
import { HomeComponent } from './modules/user/home/home.component';
import { TermsResolver } from './app.resolver';
import { InvoiceViewComponent } from './modules/user/profile/invoices/invoice-view/invoice-view.component';

const routes: Routes = [
  // Non-authenticated routes
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./modules/auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./modules/auth/forgot-passowrd/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'token',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./modules/auth/token/token.module').then((m) => m.TokenModule),
  },
  {
    path: 'new-password',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./modules/auth/new-password/new-password.module').then(
        (m) => m.NewPasswordModule
      ),
  },
  {
    path: 'login-skill',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./modules/auth/login-skill/login-skill.module').then(
        (m) => m.LoginSkillModule
      ),
  },
  {
    path: 'first-access',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/auth/first-access/first-access.module').then(
        (m) => m.FirstAccessModule
      ),
  },
  {
    path: 'terms',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/auth/terms/terms.module').then((m) => m.TermsModule),
  },

  {
    path: 'terms-redirect',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/auth/terms/term-redirect/terms-redirect.module').then(
        (m) => m.TermsModule
      ),
  },
  {
    path: 'invoice/:id',
    canActivate: [AuthGuard],
    component: InvoiceViewComponent,
  },
  // Authenticated routes
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
    resolve: {
      termsResolver: TermsResolver
    },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/user/home/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/user/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'classes',
        loadChildren: () =>
          import('./modules/user/home/classes/classes.module').then(
            (m) => m.ClassesModule
          ),
      },
      {
        path: 'documents',
        loadChildren: () =>
          import('./modules/user/home/documents/documents.module').then(
            (m) => m.DocumentsModule
          ),
      },
      {
        path: 'chat',
        loadChildren: () =>
          import(
            './modules/user/home/chat-instructor/chat-instructor.module'
          ).then((m) => m.ChatInstructorModule),
      },
    ],
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    resolve: {
      termsResolver: TermsResolver
    },
    children: [
      {
        path: 'classroom',
        loadChildren: () =>
          import('./modules/user/home/classroom/classroom.module').then(
            (x) => x.ClassroomModule
          ),
      },
    ],
  },

  // Default route
  { path: '', redirectTo: 'home/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'home/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
