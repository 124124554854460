import { NgModule } from '@angular/core';
import { CreateTicketComponent } from '../user/home/tickets/components/create-ticket/create-ticket.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { IconsModule } from './icons.module';

const materialModules = [
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSortModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatTableModule,
  MatSnackBarModule,
  MatDividerModule,
  MatRadioModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatSidenavModule
]

@NgModule({
  imports: [
    TranslocoModule,
    ReactiveFormsModule,
    IconsModule,
    ...materialModules
  ],
  declarations: [
    CreateTicketComponent
  ],
  exports: [
    CreateTicketComponent
  ]
})
export class SharedModule { }