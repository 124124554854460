import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TicketClient, DepartmentTicketResponse, CurrentStudentClient, TicketResponse, Urgency } from '../../../../../../../../api-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.css']
})
export class CreateTicketComponent {
  ticket: TicketResponse;
  filter: string = '';
  status: number = 0;
  pageSize: number = 25;
  pageIndex: number = 0;

  departament: DepartmentTicketResponse[] = [];
  urgency = Urgency;

  characterCount: number = 0;

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateTicketComponent>,
    private readonly ticketClient: TicketClient,
    private readonly currentStudentClient: CurrentStudentClient,
    private readonly formbuilder: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly router: Router
  ) { }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.initForm();
    this.loadDepartments();
  }

  loadDepartments() {
    this.ticketClient.listDepartments().subscribe({
      next: (response) => {
        this.departament = response;
      }
    })
  }

  initForm() {
    this.form = this.formbuilder.group({
      departmentId: [null, [Validators.required]],
      subject: [null, Validators.required],
      description: [null, Validators.required],
      urgency: [null, Validators.required],
    })
  }

  createTicket() {
    this.currentStudentClient.createTicket(this.form.get('departmentId').value, this.form.get('subject').value, this.form.get('description').value, this.form.get('urgency').value, []).subscribe({
      next: (response) => {
        this.dialogRef.close(response);
        this.router.navigate(['home/tickets', response.id]);
        this.toastr.success("Ticket criado com sucesso!", 'Sucesso!');
      },
      error: (err) => {
        console.error(err)
        this.toastr.error("Ocorreu um erro ao criar o ticket!", 'Erro!');
        console.log(this.form.value);
      }
    })
  }

  updateCharacterCount() {
    const subjectControl = this.form.get('subject');
    if (subjectControl) {
      this.characterCount = subjectControl.value.length;
    }
  }

}