<!-- Bottom Mobile menu, show/hide based on menu state. -->
<nav
  class="safe-area sm:hidden fixed inset-x-0 bottom-0 z-50 bg-white shadow-lg"
>
  <div class="flex justify-between text-gray-500">
    <a
      routerLink="/home/dashboard"
      class="bottom-link f"
      routerLinkActive="text-default-primary"
    >
      <img
        src="assets/images/home.svg"
        alt="Inicio"
      />
      <span>{{ "home" | transloco }}</span>
    </a>
    <a
      routerLink="/home/classes"
      class="bottom-link"
      routerLinkActive="text-default-primary"
    >
      <img src="assets/images/classes.svg" alt="Classes"/>
      <span>{{ "classes" | transloco }}</span>
    </a>
    <a
      routerLink="/home/profile"
      class="bottom-link"
      routerLinkActive="text-default-primary"
    >
      <img
        src="assets/images/profile.svg"
        alt="Perfil"
      />
      <span>{{ "profile" | transloco }}</span>
    </a>
    <a
      routerLink="/home/documents"
      class="bottom-link"
      routerLinkActive="text-default-primary"
    >
      <img
        src="assets/images/clipboard-document.svg"
        alt="Suporte"
      />
      <span>{{
        "documents" | transloco
      }}</span>
    </a>
    <a
      routerLink="/home/chat"
      class="bottom-link"
      routerLinkActive="text-default-primary"
    >
      <mat-icon [svgIcon]="'alianca:chats'"
        class="h-4 w-4 filter mx-auto"
        alt="Chat"
      ></mat-icon>
      <span>{{ "chat-title" | transloco }}</span>
    </a>
  </div>
</nav>
